import React from 'react';
import { SecondaryHero } from '@latitude/hero';
import { Box } from '@latitude/box';
import { COLOR, MARGIN } from '@latitude/core/utils/constants';
import { Heading6 } from '@latitude/heading';
import { Metadata } from '@latitude/metadata';
import { Text } from '@latitude/text';
import { Vertical } from '@latitude/spacing';
import { HorizontalRule } from '@latitude/horizontal-rule';

import { SITE_URL } from '../utils/constants';
import Layout from '../components/layout';

const TermsAndConditions = ({ location }) => (
  <Layout location={location}>
    <main className="navigation-spacer">
      <Metadata
        title="Terms &amp; Conditions | Gem by Latitude "
        description="By using the Gem Finance site or downloading its materials, you agree to abide by the terms &amp; conditions set forth in this notice. Read the full T&amp;Cs here."
        canonical={`${SITE_URL}${location.pathname}`}
      />

      <SecondaryHero
        heading={
          <>
            Terms &amp; conditions
            <br /> of use
          </>
        }
        subHeading="Please read these terms carefully before using this site."
      />

      <TermsSection />
      <HorizontalRule
        css={`
          background-color: ${COLOR.TRANSPARENT};
        `}
      />
    </main>
  </Layout>
);

const TermsSection = () => (
  <Box.Section backgroundColor={COLOR.WHITE} isBodyCopy>
    <Text>
      This Web site is provided by Latitude Financial Services Australia
      Holdings Pty Ltd (&quot;us, our, we&quot;). By using the site or
      downloading materials from the site, you agree to abide by the terms and
      conditions set forth in this notice. If you do not agree to abide by these
      terms and conditions do not use the site or download materials from the
      site.
    </Text>
    <Heading6 marginTop={MARGIN.M24} marginBottom={MARGIN.M8}>
      Limited licence
    </Heading6>
    <Vertical spacing={MARGIN.TEXT}>
      <Text>
        Subject to the terms and conditions set forth in this Agreement, we
        grant you a non-exclusive, non-transferable, limited right to access,
        use and display this site and the materials thereon. You agree not to
        interrupt or attempt to interrupt the operation of the site in any way.
      </Text>
      <Text>
        We authorise you to view and download the information
        (&quot;Materials&quot;) at this Web site (&quot;Site&quot;) only for
        your personal, non-commercial use. This authorisation is not a transfer
        of title in the Materials and copies of the Materials and is subject to
        the following restrictions: 1) you must retain, on all copies of the
        Materials downloaded, all copyright and other proprietary notices
        contained in the Materials; 2) you may not modify the Materials in any
        way or reproduce or publicly display, perform, or distribute or
        otherwise use them for any public or commercial purpose; and 3) you must
        not transfer the Materials to any other person unless you give them
        notice of, and they agree to accept, the obligations arising under these
        terms and conditions of use. You agree to abide by all additional
        restrictions displayed on the Site as it may be updated from time to
        time. This Site, including all Materials, is copyrighted and protected
        by worldwide copyright laws and treaty provisions. You agree to comply
        with all copyright laws worldwide in your use of this Site and to
        prevent any unauthorised copying of the Materials. Except as expressly
        provided herein, Latitude does not grant any express or implied right to
        you under any patents, trademarks, copyrights or trade secret
        information.
      </Text>
    </Vertical>
    <Heading6 marginTop={MARGIN.M24} marginBottom={MARGIN.M8}>
      Disclaimer
    </Heading6>
    <Vertical spacing={MARGIN.TEXT}>
      <Text>
        The materials may contain inaccuracies and typographical errors.
        Latitude does not warrant the accuracy or completeness of the materials
        or the reliability of any advice, opinion, statement or other
        information displayed or distributed through the site. You acknowledge
        that any reliance on any such opinion, advice, statement, memorandum, or
        information shall be at your sole risk. Latitude reserves the right, in
        its sole discretion, to correct any errors or omissions in any portion
        of the site. Latitude may make any other changes to the site, the
        materials and the products, programs, services or prices (if any)
        described in the site at any time without notice.
      </Text>
      <Text>
        Subject to any applicable provisions of the competition and consumer act
        2010 (CTH), which cannot be modified or excluded, this site, the
        information and materials on the site, and the software made available
        on the site, are provided &quot;as is&quot; without any representation
        or warranty, express or implied, of any kind, including, but not limited
        to, warranties of merchantability, noninfringement, or fitness for any
        particular purpose.
      </Text>
    </Vertical>
    <Heading6 marginTop={MARGIN.M24} marginBottom={MARGIN.M8}>
      Third-party sites
    </Heading6>
    <Text>
      As a convenience to you, Latitude may provide, on this Site, links to Web
      sites operated by other entities. If you use these sites, you will leave
      this Site. If you decide to visit any linked site, you do so at your own
      risk and it is your responsibility to take all protective measures to
      guard against viruses or other destructive elements. Latitude makes no
      warranty or representation regarding, and does not endorse, any linked Web
      sites or the information appearing thereon or any of the products or
      services described thereon. Links do not imply that Latitude or this Site
      sponsors, endorses, is affiliated or associated with, or is legally
      authorised to use any trademark, trade name, logo or copyright symbol
      displayed in or accessible through the links, or that any linked site is
      authorised to use any trademark, trade name, logo or copyright symbol of
      Latitude or any of its affiliates or subsidiaries.
    </Text>
    <Heading6 marginTop={MARGIN.M24} marginBottom={MARGIN.M8}>
      Information provided by you
    </Heading6>
    <Vertical spacing={MARGIN.TEXT}>
      <Text>
        Unless you are applying online or using the Latitude Service Centre,
        Latitude does not want you to, and you should not, send any confidential
        or proprietary information to Latitude via the Site. You agree that any
        such information or materials that you or individuals acting on your
        behalf provide to Latitude will not be considered confidential or
        proprietary. By providing any such information or materials to Latitude,
        you grant to Latitude an unrestricted, irrevocable, worldwide,
        royalty-free license to use, reproduce, display, publicly perform,
        transmit and distribute such information and materials, and you further
        agree that Latitude is free to use any ideas, concepts or know-how that
        you or individuals acting on your behalf provide to Latitude. You
        further recognise that Latitude does not want you to, and you warrant
        that you shall not, provide any information or materials to Latitude
        that is defamatory, threatening, obscene, harassing, or otherwise
        unlawful, or that incorporates the proprietary material of another.
      </Text>
      <Text>
        Limitation of damages: in no event shall Latitude or any of its
        subsidiaries be liable to any entity for any direct, indirect, special,
        consequential or other damages (including, without limitation, any lost
        profits, business interruption, loss of information or programs or other
        data on your information handling system) that are related to the use
        of, or the inability to use, the content, materials, and functions of
        the site or any linked website, even if Latitude is expressly advised of
        the possibility of such damages.
      </Text>
    </Vertical>
    <Heading6 marginTop={MARGIN.M24} marginBottom={MARGIN.M8}>
      Changes
    </Heading6>
    <Text>
      Latitude reserves the right, at its sole discretion, to change, modify,
      add or remove any portion of this Agreement in whole or in part, at any
      time. Changes in this Agreement will be effective when notice of such
      change is posted. Your continued use of the Site after any changes to this
      Agreement are posted will be considered acceptance of those changes.
      Latitude may terminate, change, suspend or discontinue any aspect of the
      Latitude Site, including the availability of any features of the Site, at
      any time. Latitude may also impose limits on certain features and services
      or restrict your access to parts or all of the Site without notice or
      liability. Latitude may terminate the authorisation, rights and license
      given above and, upon such termination, you shall immediately destroy all
      Materials.
    </Text>
    <Heading6 marginTop={MARGIN.M24} marginBottom={MARGIN.M8}>
      International users and choice of law
    </Heading6>
    <Text>
      This Site is controlled, operated and administered by Latitude from its
      offices within the Commonwealth of Australia. Latitude makes no
      representation that materials at this site are appropriate or available
      for use at other locations outside of the Commonwealth of Australia and
      access to them from territories where their contents are illegal is
      prohibited. You may not use the Site or export the Materials in violation
      of Australian export laws and regulations. If you access this Site from a
      locations outside of the Commonwealth of Australia, you are responsible
      for compliance with all local laws. These Terms and Conditions of use
      shall be governed by the laws of the State of Victoria, Australia, without
      giving effect to its conflict of laws provisions. This Agreement
      constitutes the entire agreement between Latitude and you with respect to
      your use of the Site. Any cause of action you may have with respect to
      your use of the Site must be commenced within the period provided by any
      applicable statute of limitations. If for any reason a court of competent
      jurisdiction finds any provision of the Agreement or portion thereof, to
      be unenforceable, that provision shall be enforced to the maximum extent
      permissible so as to effect the intent of the Agreement, and the remainder
      of this Agreement shall continue in full force and effect.
    </Text>
    <Heading6 marginTop={MARGIN.M24} marginBottom={MARGIN.M8}>
      Mobile application users - limitation of liability
    </Heading6>
    <Text>
      If you have accessed this information via a mobile application program
      (&quot;App&quot;), your access to, and use of this App is at your own
      risk. Latitude acknowledges, however that certain statutory warranties
      cannot be excluded. Without limiting the above, Latitude makes no
      warranties or representations as to the accessibility, security, stability
      or reliability of this App and Latitude specifically disclaims any
      liability or responsibility for any App faults, failures or interruptions
      or the accuracy, timeliness, completeness, security or reliability of any
      communications (including, without limitation, any transactions) made
      using the App.
    </Text>
  </Box.Section>
);

export default TermsAndConditions;
